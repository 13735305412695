var EmojiFactory = {
	"u1F603": {
		"en": "Smiley Face",
		"zh": "绗戣劯",
		"tag": "\uD83D\uDE03",
		"position": "-75px 0px"
	},
	"u1F600": {
		"en": "Grinning Face",
		"zh": "绗戝樆鍢�",
		"tag": "\uD83D\uDE00",
		"position": "0px 0px"
	},
	"u1F60A": {
		"en": "Smiley",
		"zh": "寰瑧",
		"tag": "\uD83D\uDE0A",
		"position": "-1725px 0px"
	},
	"u1F609": {
		"en": "Winking Face",
		"zh": "鐪ㄧ溂",
		"tag": "\uD83D\uDE09",
		"position": "-200px 0px"
	},
	"u1F60D": {
		"en": "Heart Eyes",
		"zh": "鑹茶糠杩�",
		"tag": "\uD83D\uDE0D",
		"position": "-1800px 0px"
	},
	"u1F618": {
		"en": "Blowing Kiss",
		"zh": "椋炲惢",
		"tag": "\uD83D\uDE18",
		"position": "-375px 0px"
	},
	"u1F61A": {
		"en": "Kiss Face",
		"zh": "涔堜箞鍝�",
		"tag": "\uD83D\uDE1A",
		"position": "-1875px 0px"
	},
	"u1F61C": {
		"en": "Crazy Face",
		"zh": "璋冪毊",
		"tag": "\uD83D\uDE1C",
		"position": "-1900px 0px"
	},
	"u1F61D": {
		"en": "Tongue Out",
		"zh": "鍚愯垖澶�",
		"tag": "\uD83D\uDE1D",
		"position": "-1925px 0px"
	},
	"u1F633": {
		"en": "Flushed Face",
		"zh": "鑴哥孩",
		"tag": "\uD83D\uDE33",
		"position": "-625px 0px"
	},
	"u1F601": {
		"en": "Grinning With Smiling",
		"zh": "闇查娇鑰岀瑧",
		"tag": "\uD83D\uDE01",
		"position": "-25px 0px"
	},
	"u1F614": {
		"en": "Pensive",
		"zh": "娌夋€�",
		"tag": "\uD83D\uDE14",
		"position": "-300px 0px"
	},
	"u1F60C": {
		"en": "Pleased",
		"zh": "婊℃剰",
		"tag": "\uD83D\uDE0C",
		"position": "-1775px 0px"
	},
	"u1F612": {
		"en": "Dissatisfied",
		"zh": "涓嶆弧",
		"tag": "\uD83D\uDE12",
		"position": "-250px 0px"
	},
	"u1F61F": {
		"en": "Worried Face",
		"zh": "鑻︾摐鑴�",
		"tag": "\uD83D\uDE1F",
		"position": "-1975px 0px"
	},
	"u1F61E": {
		"en": "Disappointed Face",
		"zh": "澶辨湜",
		"tag": "\uD83D\uDE1E",
		"position": "-1950px 0px"
	},
	"u1F623": {
		"en": "Helpless Face",
		"zh": "鏃犲姪",
		"tag": "\uD83D\uDE23",
		"position": "-450px 0px"
	},
	"u1F62D": {
		"en": "Crying",
		"zh": "浼ゅ績",
		"tag": "\uD83D\uDE22",
		"position": "-425px 0px"
	},
	"u1F602": {
		"en": "Laughing Tears",
		"zh": "鍠滄瀬鑰屾常",
		"tag": "\uD83D\uDE02",
		"position": "-50px 0px"
	},
	"u1F622": {
		"en": "Sobbing",
		"zh": "鍝常",
		"tag": "\uD83D\uDE2D",
		"position": "-2075px 0px"
	},
	"u1F62A": {
		"en": "Sleepy Face",
		"zh": "鍥�",
		"tag": "\uD83D\uDE2A",
		"position": "-2000px 0px"
	},
	"u1F630": {
		"en": "Cold Sweat",
		"zh": "鍐锋睏",
		"tag": "\uD83D\uDE30",
		"position": "-550px 0px"
	},
	"u1F605": {
		"en": "Happy Sweat",
		"zh": "灏村艾",
		"tag": "\uD83D\uDE05",
		"position": "-100px 0px"
	},
	"u1F613": {
		"en": "Sweat",
		"zh": "姹�",
		"tag": "\uD83D\uDE13",
		"position": "-275px 0px"
	},
	"u1F62B": {
		"en": "Tired Face",
		"zh": "鎶撶媯",
		"tag": "\uD83D\uDE2B",
		"position": "-2025px 0px"
	},
	"u1F629": {
		"en": "Weary Face",
		"zh": "鐤叉儷",
		"tag": "\uD83D\uDE29",
		"position": "-525px 0px"
	},
	"u1F628": {
		"en": "Fearful Face",
		"zh": "鍙€�",
		"tag": "\uD83D\uDE28",
		"position": "-500px 0px"
	},
	"u1F631": {
		"en": "Scream",
		"zh": "灏栧彨",
		"tag": "\uD83D\uDE31",
		"position": "-575px 0px"
	},
	"u1F621": {
		"en": "Angry Face",
		"zh": "鐢熸皵",
		"tag": "\uD83D\uDE21",
		"position": "-400px 0px"
	},
	"u1F624": {
		"en": "Mad Face",
		"zh": "鎬掓皵鍐插啿",
		"tag": "\uD83D\uDE24",
		"position": "-475px 0px"
	},
	"u1F616": {
		"en": "Confounded Face",
		"zh": "钂欑緸",
		"tag": "\uD83D\uDE16",
		"position": "-350px 0px"
	},
	"u1F606": {
		"en": "Big Grin",
		"zh": "澶х瑧",
		"tag": "\uD83D\uDE06",
		"position": "-125px 0px"
	},
	"u1F60B": {
		"en": "Hungry",
		"zh": "棣�",
		"tag": "\uD83D\uDE0B",
		"position": "-1750px 0px"
	},
	"u1F637": {
		"en": "Mask Face",
		"zh": "鍙ｇ僵",
		"tag": "\uD83D\uDE37",
		"position": "-725px 0px"
	},
	"u1F60E": {
		"en": "Sunglasses",
		"zh": "澧ㄩ暅",
		"tag": "\uD83D\uDE0E",
		"position": "-1825px 0px"
	},
	"u1F634": {
		"en": "Sleeping",
		"zh": "鐫＄湢",
		"tag": "\uD83D\uDE34",
		"position": "-650px 0px"
	},
	"u1F635": {
		"en": "Dizzy Face",
		"zh": "澶存檿鐪艰姳",
		"tag": "\uD83D\uDE35",
		"position": "-675px 0px"
	},
	"u1F632": {
		"en": "Shocked Face",
		"zh": "闇囨儕",
		"tag": "\uD83D\uDE32",
		"position": "-600px 0px"
	},
	"u1F608": {
		"en": "Purple Devil",
		"zh": "灏忔伓榄�",
		"tag": "\uD83D\uDE08",
		"position": "-175px 0px"
	},
	"u1F47F": {
		"en": "Devil",
		"zh": "鎭堕瓟",
		"tag": "\uD83D\uDC7F",
		"position": "-1600px 0px"
	},
	"u1F62F": {
		"en": "Surprised Face",
		"zh": "鎯婂憜",
		"tag": "\uD83D\uDE2F",
		"position": "-2100px 0px"
	},
	"u1F62C": {
		"en": "Grimacing Face",
		"zh": "鎵鑴�",
		"tag": "\uD83D\uDE2C",
		"position": "-2050px 0px"
	},
	"u1F615": {
		"en": "Confused",
		"zh": "鍥版儜",
		"tag": "\uD83D\uDE15",
		"position": "-325px 0px"
	},
	"u1F636": {
		"en": "Mouthless",
		"zh": "鏃犲彛",
		"tag": "\uD83D\uDE36",
		"position": "-700px 0px"
	},
	"u1F607": {
		"en": "Halo",
		"zh": "澶╀娇鍏夌幆",
		"tag": "\uD83D\uDE07",
		"position": "-150px 0px"
	},
	"u1F60F": {
		"en": "Smirking Face",
		"zh": "鍌荤瑧",
		"tag": "\uD83D\uDE0F",
		"position": "-1850px 0px"
	},
	"u1F611": {
		"en": "Expressionless Face",
		"zh": "闈㈡棤琛ㄦ儏",
		"tag": "\uD83D\uDE11",
		"position": "-225px 0px"
	},
	"u1F648": {
		"en": "See No Monkey",
		"zh": "涓嶇湅",
		"tag": "\uD83D\uDE48",
		"position": "-2675px 0px"
	},
	"u1F649": {
		"en": "Hear No Monkey",
		"zh": "涓嶅惉",
		"tag": "\uD83D\uDE49",
		"position": "-2700px 0px"
	},
	"u1F64A": {
		"en": "No Speaking",
		"zh": "闂槾",
		"tag": "\uD83D\uDE4A",
		"position": "-2125px 0px"
	},
	"u1F47D": {
		"en": "Alien",
		"zh": "澶栨槦浜�",
		"tag": "\uD83D\uDC7D",
		"position": "-1575px 0px"
	},
	"u1F4A9": {
		"en": "Pile Of Poo",
		"zh": "渚夸究",
		"tag": "\uD83D\uDCA9",
		"position": "-1025px 0px"
	},
	"u1F494": {
		"en": "Broken Heart",
		"zh": "蹇冪",
		"tag": "\uD83D\uDC94",
		"position": "-2600px 0px"
	},
	"u1F525": {
		"en": "Fire",
		"zh": "鐏�",
		"tag": "\uD83D\uDD25",
		"position": "-2625px 0px"
	},
	"u1F4A2": {
		"en": "Anger",
		"zh": "鎰ゆ€�",
		"tag": "\uD83D\uDCA2",
		"position": "-950px 0px"
	},
	"u1F4A4": {
		"en": "Zzz",
		"zh": "ZZZ",
		"tag": "\uD83D\uDCA4",
		"position": "-1000px 0px"
	},
	"u1F6AB": {
		"en": "Prohibited",
		"zh": "绂佹",
		"tag": "\uD83D\uDEAB",
		"position": "-1175px 0px"
	},
	"u2B50": {
		"en": "Star",
		"zh": "鏄熸槦",
		"tag": "\u2B50",
		"position": "-2750px 0px"
	},
	"u26A1": {
		"en": "Lightning Bolt",
		"zh": "闂數",
		"tag": "\u26A1",
		"position": "-2825px 0px"
	},
	"u1F319": {
		"en": "Drescent Moon",
		"zh": "寮湀",
		"tag": "\uD83C\uDF19",
		"position": "-2175px 0px"
	},
	"u2600": {
		"en": "Sunny",
		"zh": "鏅存湕",
		"tag": "\u2600",
		"position": "-3075px 0px"
	},
	"u26C5": {
		"en": "Cloudy",
		"zh": "澶氫簯",
		"tag": "\u26C5",
		"position": "-2900px 0px"
	},
	"u2601": {
		"en": "Cloud",
		"zh": "浜戝僵",
		"tag": "\u2601",
		"position": "-3100px 0px"
	},
	"u2744": {
		"en": "Snowflake",
		"zh": "闆姳",
		"tag": "\u2744",
		"position": "-3175px 0px"
	},
	"u2614": {
		"en": "Umbrella",
		"zh": "闆ㄤ紴",
		"tag": "\u2614",
		"position": "-3125px 0px"
	},
	"u26C4": {
		"en": "Snowman",
		"zh": "闆汉",
		"tag": "\u26C4",
		"position": "-2875px 0px"
	},
	"u1F44D": {
		"en": "Thumbs Up",
		"zh": "璧�",
		"tag": "\uD83D\uDC4D",
		"position": "-1400px 0px"
	},
	"u1F44E": {
		"en": "Thumbs Down",
		"zh": "鍠濆€掑僵",
		"tag": "\uD83D\uDC4E",
		"position": "-1425px 0px"
	},
	"u1F91D": {
		"en": "Handshake",
		"zh": "鎻℃墜",
		"tag": "\uD83E\uDD1D",
		"position": "-3200px 0px"
	},
	"u1F44C": {
		"en": "Ok Hand",
		"zh": "娌￠棶棰�",
		"tag": "\uD83D\uDC4C",
		"position": "-1375px 0px"
	},
	"u1F44A": {
		"en": "Raised Fist",
		"zh": "涓捐捣鎷冲ご",
		"tag": "\u270A",
		"position": "-2975px 0px"
	},
	"u270A": {
		"en": "Oncoming Fist",
		"zh": "鍑绘嫵",
		"tag": "\uD83D\uDC4A",
		"position": "-1350px 0px"
	},
	"u270C": {
		"en": "Victory Hand",
		"zh": "鑰�",
		"tag": "\u270C",
		"position": "-3025px 0px"
	},
	"u270B": {
		"en": "Raised Hand",
		"zh": "涓炬墜",
		"tag": "\u270B",
		"position": "-3000px 0px"
	},
	"u1F64F": {
		"en": "Folded Hands",
		"zh": "绁堢シ",
		"tag": "\uD83D\uDE4F",
		"position": "-2150px 0px"
	},
	"u261D": {
		"en": "Pointing Up",
		"zh": "绗竴",
		"tag": "\u261D",
		"position": "-2925px 0px"
	},
	"u1F44F": {
		"en": "Clapping Hands",
		"zh": "榧撴帉",
		"tag": "\uD83D\uDC4F",
		"position": "-1450px 0px"
	},
	"u1F4AA": {
		"en": "Flexed Biceps",
		"zh": "鑲岃倝",
		"tag": "\uD83D\uDCAA",
		"position": "-1050px 0px"
	},
	"u1F46A": {
		"en": "Family",
		"zh": "瀹跺涵",
		"tag": "\uD83D\uDC6A",
		"position": "-1475px 0px"
	},
	"u1F46B": {
		"en": "Couple",
		"zh": "鎯呬荆",
		"tag": "\uD83D\uDC6B",
		"position": "-1500px 0px"
	},
	"u1F47C": {
		"en": "Baby Angel",
		"zh": "瀹濊礉澶╀娇",
		"tag": "\uD83D\uDC7C",
		"position": "-1550px 0px"
	},
	"u1F434": {
		"en": "Horse",
		"zh": "椹�",
		"tag": "\uD83D\uDC34",
		"position": "-2475px 0px"
	},
	"u1F436": {
		"en": "Dog",
		"zh": "鐙�",
		"tag": "\uD83D\uDC36",
		"position": "-2500px 0px"
	},
	"u1F437": {
		"en": "Pig",
		"zh": "鐚�",
		"tag": "\uD83D\uDC37",
		"position": "-2525px 0px"
	},
	"u1F47B": {
		"en": "Ghost",
		"zh": "楝�",
		"tag": "\uD83D\uDC7B",
		"position": "-1525px 0px"
	},
	"u1F339": {
		"en": "Rose",
		"zh": "鐜懓",
		"tag": "\uD83C\uDF39",
		"position": "-2225px 0px"
	},
	"u1F33B": {
		"en": "Sunflower",
		"zh": "鍚戞棩钁�",
		"tag": "\uD83C\uDF3B",
		"position": "-1250px 0px"
	},
	"u1F332": {
		"en": "Pine Tree",
		"zh": "鏉炬爲",
		"tag": "\uD83C\uDF32",
		"position": "-2200px 0px"
	},
	"u1F384": {
		"en": "Christmas Tree",
		"zh": "鍦ｈ癁鏍�",
		"tag": "\uD83C\uDF84",
		"position": "-2400px 0px"
	},
	"u1F381": {
		"en": "Wrapped Gift",
		"zh": "绀肩墿",
		"tag": "\uD83C\uDF81",
		"position": "-2350px 0px"
	},
	"u1F389": {
		"en": "Party Popper",
		"zh": "鑱氫細绀艰姳",
		"tag": "\uD83C\uDF89",
		"position": "-2425px 0px"
	},
	"u1F4B0": {
		"en": "Money Bag",
		"zh": "閽辫",
		"tag": "\uD83D\uDCB0",
		"position": "-1075px 0px"
	},
	"u1F382": {
		"en": "Birthday Cake",
		"zh": "鐢熸棩铔嬬硶",
		"tag": "\uD83C\uDF82",
		"position": "-2375px 0px"
	},
	"u1F356": {
		"en": "Barbecue",
		"zh": "BBQ",
		"tag": "\uD83C\uDF56",
		"position": "-2275px 0px"
	},
	"u1F35A": {
		"en": "Cooked Rice",
		"zh": "绫抽キ",
		"tag": "\uD83C\uDF5A",
		"position": "-1275px 0px"
	},
	"u1F366": {
		"en": "Ice Cream",
		"zh": "鍐版穱娣�",
		"tag": "\uD83C\uDF66",
		"position": "-2300px 0px"
	},
	"u1F36B": {
		"en": "Chocolate Bar",
		"zh": "宸у厠鍔�",
		"tag": "\uD83C\uDF6B",
		"position": "-1300px 0px"
	},
	"u1F349": {
		"en": "Watermelon",
		"zh": "瑗跨摐",
		"tag": "\uD83C\uDF49",
		"position": "-2250px 0px"
	},
	"u1F377": {
		"en": "Wine Glass",
		"zh": "绾㈤厭",
		"tag": "\uD83C\uDF77",
		"position": "-2325px 0px"
	},
	"u1F37B": {
		"en": "Cheers",
		"zh": "骞叉澂",
		"tag": "\uD83C\uDF7B",
		"position": "-1325px 0px"
	},
	"u2615": {
		"en": "Coffee",
		"zh": "鍜栧暋",
		"tag": "\u2615",
		"position": "-3150px 0px"
	},
	"u1F3C0": {
		"en": "Basketball",
		"zh": "绡悆",
		"tag": "\uD83C\uDFC0",
		"position": "-825px 0px"
	},
	"u26BD": {
		"en": "Soccer Ball",
		"zh": "瓒崇悆",
		"tag": "\u26BD",
		"position": "-2850px 0px"
	},
	"u1F3C2": {
		"en": "Snowboarder",
		"zh": "鍗曟澘婊戦洩",
		"tag": "\uD83C\uDFC2",
		"position": "-850px 0px"
	},
	"u1F3A4": {
		"en": "Microphone",
		"zh": "楹﹀厠椋�",
		"tag": "\uD83C\uDFA4",
		"position": "-750px 0px"
	},
	"u1F3B5": {
		"en": "Musical Note",
		"zh": "闊充箰",
		"tag": "\uD83C\uDFB5",
		"position": "-800px 0px"
	},
	"u1F3B2": {
		"en": "Game Die",
		"zh": "楠板瓙",
		"tag": "\uD83C\uDFB2",
		"position": "-775px 0px"
	},
	"u1F004": {
		"en": "Mahjong Red Dragon",
		"zh": "楹诲皢",
		"tag": "\uD83C\uDC04",
		"position": "-900px 0px"
	},
	"u1F451": {
		"en": "Crown",
		"zh": "鐜嬪啝",
		"tag": "\uD83D\uDC51",
		"position": "-2550px 0px"
	},
	"u1F484": {
		"en": "Lipstick",
		"zh": "鍙ｇ孩",
		"tag": "\uD83D\uDC84",
		"position": "-2575px 0px"
	},
	"u1F48B": {
		"en": "Kiss",
		"zh": "鍚�",
		"tag": "\uD83D\uDC8B",
		"position": "-1650px 0px"
	},
	"u1F48D": {
		"en": "Ring",
		"zh": "鎴掓寚",
		"tag": "\uD83D\uDC8D",
		"position": "-1675px 0px"
	},
	"u1F4DA": {
		"en": "Books",
		"zh": "涔︾睄",
		"tag": "\uD83D\uDCDA",
		"position": "-1100px 0px"
	},
	"u1F393": {
		"en": "Graduation Cap",
		"zh": "姣曚笟甯�",
		"tag": "\uD83C\uDF93",
		"position": "-2450px 0px"
	},
	"u270F": {
		"en": "Pencil",
		"zh": "閾呯瑪",
		"tag": "\u270F",
		"position": "-3050px 0px"
	},
	"u1F3E1": {
		"en": "House With Garden",
		"zh": "鎴垮瓙",
		"tag": "\uD83C\uDFE1",
		"position": "-875px 0px"
	},
	"u1F6BF": {
		"en": "Shower",
		"zh": "娣嬫荡",
		"tag": "\uD83D\uDEBF",
		"position": "-1200px 0px"
	},
	"u1F4A1": {
		"en": "Light Bulb",
		"zh": "鐏场",
		"tag": "\uD83D\uDCA1",
		"position": "-925px 0px"
	},
	"u1F4DE": {
		"en": "Telephone Receiver",
		"zh": "鐢佃瘽鍚瓛",
		"tag": "\uD83D\uDCDE",
		"position": "-1125px 0px"
	},
	"u1F4E2": {
		"en": "Loudspeaker",
		"zh": "鎵╅煶鍣�",
		"tag": "\uD83D\uDCE2",
		"position": "-1150px 0px"
	},
	"u1F556": {
		"en": "Clock",
		"zh": "琛�",
		"tag": "\uD83D\uDD56",
		"position": "-2650px 0px"
	},
	"u23F0": {
		"en": "Alarm Clock",
		"zh": "闂归挓",
		"tag": "\u23F0",
		"position": "-2775px 0px"
	},
	"u23F3": {
		"en": "Hourglass",
		"zh": "娌欐紡",
		"tag": "\u23F3",
		"position": "-2800px 0px"
	},
	"u1F4A3": {
		"en": "Bomb",
		"zh": "鐐稿脊",
		"tag": "\uD83D\uDCA3",
		"position": "-975px 0px"
	},
	"u1F52B": {
		"en": "Pistol",
		"zh": "鎵嬫灙",
		"tag": "\uD83D\uDD2B",
		"position": "-1700px 0px"
	},
	"u1F48A": {
		"en": "Capsule",
		"zh": "鑽�",
		"tag": "\uD83D\uDC8A",
		"position": "-1625px 0px"
	},
	"u1F680": {
		"en": "Rocket",
		"zh": "鐏",
		"tag": "\uD83D\uDE80",
		"position": "-2725px 0px"
	},
	"u1F30F": {
		"en": "Globe",
		"zh": "鍦扮悆",
		"tag": "\uD83C\uDF0F",
		"position": "-1225px 0px"
	}
};
let EmojiList = []

function setEmojiList() {
	EmojiList.length = 0;
	for (var unicode in EmojiFactory) {
		var detail = EmojiFactory[unicode];
		if (detail.tag) {
			var lang = 'en';
			var symbol = detail[lang];
			EmojiList.push({
				unicode: unicode,
				symbol: '[' + symbol + ']',
				emoji: detail.tag,
			});
		}
	}
}
setEmojiList()
export default EmojiList
