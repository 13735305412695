import Layout from '@/layout'

export default {
    path: '/my',
    component: Layout,
    children: [
        {
            path: '/',
            component: () => import('@/views/my/index'),
            redirect: '/my/course',
            children: [
                {
                    path: 'course',
                    component: () => import('@/views/my/course'),
                    meta: {
                        title: '我的课程'
                    }
                },
                {
                    path: 'order',
                    component: () => import('@/views/my/order'),
                    meta: {
                        title: '我的订单'
                    }
                },
                {
                    path: 'record',
                    component: () => import('@/views/my/record'),
                    meta: {
                        title: '听课记录'
                    }
                }
            ]
        }
    ]
}