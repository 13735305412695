import Layout from '@/layout'

export default {
    path: '/university',
    component: Layout,
    children: [
        {
            path: 'index',
            name: 'universityIndex',
            component: () => import('@/views/university/index'),
            meta: {
                title: '院校指南'
            }
        },
        {
            path: 'detail/:id',
            name: 'universityDetail',
            component: () => import('@/views/university/detail'),
            meta: {
                title: '院校详情'
            }
        }
    ]
}