import axios from 'axios'
import store from '@/store/index'
import router from '@/router/index'

// 登录
const toLogin = () => {
    router.push({
        path: '/login',
        query: {
            redirect: router.currentRoute.fullPath
        }
    })
}

// 初始化
const api = axios.create({
    baseURL: process.env.VUE_APP_API_ROOT,
    timeout: 500000,
    responseType: 'json'
    // withCredentials: true
})

// 配置请求拦截器
api.interceptors.request.use(
    request => {
        request.headers.token = store.state.token
        return request
    }
)

// 配置响应拦截器
api.interceptors.response.use(
    response => {
        const res = response.data
        // 判断响应状态
        if (res.code !== 200) {
            // 需要登录
            if (res.code === 401) {
                store.commit('removeUserData')
                toLogin()
                return false
            }
            return Promise.reject(res)
        }
        // 正常返回
        return Promise.resolve(res)
    },
    error => {
        return Promise.reject(error)
    }
)

export default api