import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: localStorage.getItem("token") ? localStorage.getItem("token") : '',
        userInfo: localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : {},
        userType: localStorage.getItem("userType") ? parseInt(localStorage.getItem("userType")) : 0
    },
    mutations: {
        setToken(state, data) {
            state.token = data
            localStorage.setItem("token", state.token)
        },
        setUserData(state, data) {
            state.userInfo = data
            localStorage.setItem("userInfo", JSON.stringify(state.userInfo))
        },
        setUserType(state, data) {
            state.userType = data
            localStorage.setItem("userType", state.userType)
            // 刷新当前页面
            location.reload()
        },
        removeUserData(state) {
            state.token = ''
            localStorage.removeItem("token")
            state.userInfo = {}
            localStorage.removeItem("userInfo")
        }
    }
})