import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Layout from '@/layout'
import EmptyLayout from '@/layout/empty'

// 基础路由
const baseRoutes = [
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: '/',
                name: 'index',
                component: () => import('@/views/index'),
                meta: {
                    title: '法教授'
                }
            }
        ]
    },
    {
        path: 'reload',
        name: 'reload',
        component: () => import('@/views/reload')
    },
    {
        path: '/login',
        component: EmptyLayout,
        children: [
            {
                path: '/',
                name: 'login',
                component: () => import('@/views/login'),
                meta: {
                    title: '登录-法教授'
                }
            }
        ]
    }
]

// 其他：政策、帮助中心
import other from "@/router/module/other"
// 课程资源
import packageContent from "@/router/module/package";
// 通识教育
import general from "@/router/module/general"
// 线下教学
import offline from "@/router/module/offline"
// 保研留学
import abroad from "@/router/module/abroad"
// 个人中心
import my from "@/router/module/my"
// 录播课
import course from "@/router/module/course"
// 直播课
import live from "@/router/module/live";
// 院校指南
import university from "@/router/module/university"
// 全国分校
import branch from "@/router/module/branch"

// 业务路由
const routes = baseRoutes.concat(
    packageContent,
    general,
    offline,
    abroad,
    my,
    course,
    live,
    university,
    branch,
    other
)

// 404 路由
routes.push({
    path: '*',
    component: () => import('@/views/404'),
    meta: {
        title: '404',
        sidebar: false
    }
})

const router = new VueRouter({
    mode: process.env.VUE_APP_ROUTER_MODE,
    routes
})

// 解决路由在 push/replace 了相同地址报错的问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err)
}

router.beforeEach(async (to, from, next) => {
    if (to.meta.title) {
        //判断是否有标题
        document.title = to.meta.title + '-' + '法教授'
    }
    next()
})

export default router