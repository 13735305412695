import Layout from '@/layout'

export default {
    path: '/branch',
    component: Layout,
    children: [
        {
            path: 'index',
            name: 'branchIndex',
            component: () => import('@/views/branch/index'),
            meta: {
                title: '全国分校'
            }
        }
    ]
}