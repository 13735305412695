import Layout from '@/layout'

export default {
    path: '/abroad',
    component: Layout,
    children: [
        {
            path: '/',
            component: () => import('@/views/abroad/index'),
            redirect: '/abroad/abroad',
            children: [
                {
                    path: 'abroad',
                    component: () => import('@/views/abroad/abroad'),
                    meta: {
                        title: '出国'
                    }
                },
                {
                    path: 'camp',
                    component: () => import('@/views/abroad/camp'),
                    meta: {
                        title: '保研夏令营'
                    }
                }
            ]
        }
    ]
}