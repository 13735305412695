import EmptyLayout from '@/layout/empty'

export default {
    path: '/live',
    component: EmptyLayout,
    children: [
        {
            path: 'index/:id',
            name: 'liveIndex',
            component: () => import('@/views/live/index'),
            meta: {
                title: '直播课'
            }
        }
    ]
}