import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "./api"
Vue.config.productionTip = false

// 注册 axios
Vue.prototype.$api = axios

// 注册 ElementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

// 全局组件自动注册
import './components/autoRegister'

// 自动加载 svg 图标
const req = require.context('./assets/icons', false, /\.svg$/)
const requireAll = requireContext => requireContext.keys().map(requireContext)
requireAll(req)

// 引入重置样式
import 'normalize.css/normalize.css'
import './assets/styles/reset.scss'


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
