import Layout from '@/layout'

export default {
    path: '/offline',
    component: Layout,
    children: [
        {
            path: 'index',
            name: 'offlineIndex',
            component: () => import('@/views/offline/index'),
            meta: {
                title: '线下教学'
            }
        }
    ]
}