<template>
    <div class="bar">
        <div class="content">
            <el-row type="flex" align="middle">
                <el-col :span="19">
                    <div class="title">
                        <!-- logo  -->
                        <div class="logo">
                            <router-link :to="{name: 'index'}">
                                <el-image :src="require('@/assets/images/logo-long.png')"/>
                            </router-link>
                        </div>
                        <!-- 标题 -->
                        <div>
                            <el-menu :default-active="activeIndex"
                                     router mode="horizontal"
                                     active-text-color="#f29100"
                                     text-color="#3a3a3a">
                                <el-menu-item index="/">首页</el-menu-item>
                                <el-menu-item index="/package/index">线上课程</el-menu-item>
                                <el-menu-item index="/offline/index">线下教学</el-menu-item>
                                <el-menu-item index="/general/index">通识教育</el-menu-item>
                                <el-menu-item index="/abroad/">保研留学</el-menu-item>
                                <el-menu-item index="/branch/index">全国分校</el-menu-item>
                                <el-menu-item index="/my/">个人中心</el-menu-item>
                            </el-menu>
                        </div>
                    </div>
                </el-col>
                <el-col :span="5">
                    <div class="title" style="float: right; align-items: center;">
                        <div class="select-title">
                            <router-link v-if="!$store.state.token" :to="{name: 'login'}"
                                         style="text-decoration: none; color: #3a3a3a;">
                                <i class="el-icon-s-custom" style="margin-right: 10px; font-size: 20px;"></i>登录
                            </router-link>
                            <el-tooltip v-else effect="dark" content="点击退出登录" placement="bottom-end">
                                <div style="display: flex; align-items: center; color:#3a3a3a;" @click="logout">
                                    <el-avatar :src="$store.state.userInfo.avatarUrl" :size="40"
                                               style="margin-right: 10px;"/>
                                    {{ $store.state.userInfo.nickName }}
                                </div>
                            </el-tooltip>
                        </div>
                        <el-divider direction="vertical"></el-divider>
                        <el-dropdown>
                            <div class="select-title">
                                {{ this.$store.state.userType ? '学硕' : '法硕' }}<i class="el-icon-caret-bottom"></i>
                            </div>
                            <el-dropdown-menu slot="dropdown" style="z-index: 99999 !important;">
                                <el-dropdown-item @click.native="chooseUserType(0)" style="z-index: 99999 !important;">
                                    法硕
                                </el-dropdown-item>
                                <el-dropdown-item @click.native="chooseUserType(1)" style="z-index: 99999 !important;">
                                    学硕
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    data() {
        return {
            activeIndex: '/'
        }
    },
    mounted() {
        this.setPath()
    },
    watch: {
        $route() {
            this.setPath()
        }
    },
    methods: {
        // 选择用户类型
        chooseUserType(val) {
            // 登录状态下再同步
            if (this.$store.state.token) {
                this.$api.get('/auth/info/updateUserType', {
                    params: {
                        userType: val
                    }
                }).then(res => {
                    this.$store.commit('setUserType', res.data.userType)
                }).catch(err => {
                    console.log(err)
                })
            } else {
                this.$store.commit('setUserType', val)
            }
        },
        // 设置路由路径
        setPath() {
            if (this.$route.path.indexOf('/abroad/') !== -1) {
                this.activeIndex = '/abroad/'
            } else if (this.$route.path.indexOf('/my/') !== -1) {
                this.activeIndex = '/my/'
            } else {
                this.activeIndex = this.$route.path
            }
        },
        // 退出登录
        logout() {
            this.$store.commit('removeUserData')
            this.$router.push({name: 'login'})
        }
    }
}
</script>

<style>
.el-menu.el-menu--horizontal {
    border-bottom: none !important;
}
</style>

<style scoped lang="scss">
.bar {
    width: 100%;
    background: $sr-bg-color;
    box-shadow: 0 2px 6px 0 rgb(187 187 187 / 50%);
    margin-bottom: 20px;
    padding: 10px 0;
    height: 80px;
    position: fixed;
    top: 0;
    z-index: 999 !important;

    .content {
        width: 1200px;
        margin: 0 auto;

        .title {
            display: flex;
            align-items: center;

            .logo {
                width: 130px;
                margin-right: 30px;
            }

            li {
                font-size: 18px;
            }

            .select-title {
                font-size: 18px;
                cursor: pointer;
            }
        }
    }
}
</style>