import Layout from '@/layout'

export default {
    path: '/package',
    component: Layout,
    children: [
        {
            path: 'index',
            name: 'packageIndex',
            component: () => import('@/views/package/index'),
            meta: {
                title: '课程列表'
            }
        },
        {
            path: 'detail/:id',
            name: 'packageDetail',
            component: () => import('@/views/package/detail'),
            meta: {
                title: '课程详情'
            }
        }
    ]
}