import EmptyLayout from '@/layout/empty'

export default {
    path: '/other',
    component: EmptyLayout,
    children: [
        {
            path: 'userPrivacy',
            name: 'userPrivacy',
            component: () => import('@/views/other/userPrivacy'),
            meta: {
                title: '法教授隐私政策'
            }
        },
        {
            path: 'userAgreement',
            name: 'userAgreement',
            component: () => import('@/views/other/userAgreement'),
            meta: {
                title: '法教授用户协议'
            }
        },
        {
            path: 'userHelp',
            name: 'userHelp',
            component: () => import('@/views/other/userHelp'),
            meta: {
                title: '帮助中心'
            }
        }
    ]
}