import EmptyLayout from '@/layout/empty'

export default {
    path: '/course',
    component: EmptyLayout,
    children: [
        {
            path: 'index/:id',
            name: 'courseIndex',
            component: () => import('@/views/course/index'),
            meta: {
                title: '录播课'
            }
        }
    ]
}