<template>
    <div class="page-body">
        <!--回到顶部-->
        <el-backtop />
        <!-- 头部导航栏 -->
        <top-bar/>
        <!-- 内容区域 -->
        <div style="padding-top: 100px;">
            <!-- 页面主体 -->
            <router-view style="width: 1200px; margin: 0 auto; min-height: calc(100vh - 426px)"/>
            <!-- 底部版权信息 -->
            <copy-right/>
        </div>
    </div>
</template>

<script>
import TopBar from '@/layout/TopBar'
import CopyRight from '@/layout/CopyRight'

export default {
    name: "index",
    components: {
        TopBar,
        CopyRight
    }
}
</script>

<style scoped lang="scss">
.page-body {
    background-color: $sr-main-bg;
    min-height: 100vh;
}

::v-deep .el-backtop {
    color: $sr-main-color;
}
</style>