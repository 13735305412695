import Layout from '@/layout'

export default {
    path: '/general',
    component: Layout,
    children: [
        {
            path: 'index',
            name: 'generalIndex',
            component: () => import('@/views/general/index'),
            meta: {
                title: '通识教育'
            }
        }
    ]
}