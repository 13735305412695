<template>
    <div class="card">
        <div class="content">
            <!--标题-->
            <div class="title">
                {{ title }}
            </div>
            <!--副标题-->
            <div class="tips">
                {{ tips }}
            </div>
        </div>
        <!--图标-->
        <svg-icon class="svg-icon" name="university"/>
    </div>
</template>

<script>
export default {
    name: "ColorCard",
    props: {
        // 标题
        title: {
            type: String,
            default: ''
        },
        // 提示
        tips: {
            type: String,
            default: ''
        }
    }
}
</script>

<style scoped lang="scss">

.card {
    background: linear-gradient(50deg, rgb(253, 120, 62), rgb(255, 196, 128));
    color: #FFFFFF;
    width: 100%;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #eee;
    overflow: hidden;

    .content {
        position: relative;
        top: 45px;
        left: 30px;

        .title {
            font-size: 22px;
            font-weight: bold;
        }

        .tips {
            margin-top: 20px;
        }
    }

    &:hover {
        //transition: all 0.2s;
        //transform: translate3d(0, -10px, 0);

        .svg-icon {
            transition: .3s;
            right: -1.85em;
            top: -0.6em;
        }
    }

    .svg-icon {
        position: relative;
        width: 1em;
        height: 1em;
        font-size: 100px;
        vertical-align: -.15em;
        fill: currentColor;
        right: -2em;
        top: -0.70em;
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg);
    }
}

</style>