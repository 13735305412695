<template>
    <div class="emoji-bg">
        <div v-for="(item, index) in emojiList"
             :key="index"
             class="icon"
             @click="$emit('clickIcon', item)">
            {{ item.emoji }}
        </div>
    </div>
</template>

<script>
import emojiList from '@/util/emojiData'
export default {
    name: "EmojiIcon",
    data() {
        return {
            emojiList
        }
    }
}
</script>

<style scoped lang="scss">
.emoji-bg {
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .icon {
        width: 33px;
        height: 33px;
        font-size: 20px;
        cursor: pointer;
        display: inline-block;
    }
}
</style>